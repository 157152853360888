<template>
  <b-overlay
    :opacity="0.5"
    :show="loading"
    rounded="sm"
    :blur="'true'"
  >
    <b-card>
      <div>
        <ValidationObserver
          ref="createStaffForm"
          v-slot="{ handleSubmit }"
          slim
        >
          <b-form
            ref="form"
            class="repeater-form"
            autocomplete="off"
            @submit.prevent="handleSubmit(onClickSubmit)"
          >
            <b-col>
              <b-row class="mt-2 mb-8">
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    class="required"
                    label="Merchant"
                    label-for="merchantBusiness"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Merchant"
                      vid="Merchant"
                      rules="required"
                    >
                      <v-select
                        v-model="merchant"
                        :options="merchants"
                        :reduce="option => option"
                        label="name"
                        placeholder="Select Merchant"
                        @input="getBusinessList()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    class="required"
                    label="Merchant Business"
                    label-for="merchantBusiness"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Merchant Business"
                      vid="Merchant Business"
                      rules="required"
                    >
                      <v-select
                        v-model="merchant_business_id"
                        :options="businesses"
                        :reduce="option => option.id"
                        label="business_name"
                        autocomplete="nope"
                        placeholder="Select Business profile"
                        @input="!orderSettings.origin_city_freeze ? getAddress() : () =>{}"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="!orderSettings.origin_city_freeze">
                <!-- Pickup Address -->
                <b-col
                  v-if="!orderSettings.origin_city_freeze"
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    class="required"
                    label="Pickup Address"
                    label-for="pickupAddress"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="pickupaddress"
                      rules="required"
                    >
                      <v-select
                        v-model="pickupAddress"
                        :options="businessAddresses"
                        :reduce="option => option.id"
                        label="pickup_address"
                        autocomplete="nope"
                        placeholder="Select Pickup Address"
                        @input="setDefaultOriginCity()"
                      >
                        <template v-slot:option="option">
                          {{ option.pickup_address }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    class="required"
                    label="Origin City"
                    label-for="originCity"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Origin City"
                      vid="Origin City"
                      rules="required"
                    >
                      <b-form-input
                        v-model="origin_city_id"
                        :hidden="true"
                      />
                      <b-form-input
                        v-model="origin_city_name"
                        disabled="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Col Loop -->
            <b-col
              v-for="(order, index) in orders"
              :id="order.id"
              :key="index"
              ref="row"
            >
              <b-col
                cols="3"
                md="1"
              >
                <hr>
              </b-col>
              <b-col cols="6">
                <h6>{{ numberToWordWithTh(index + 1) }} Order</h6>
              </b-col>
              <b-col cols="9">
                <hr>
              </b-col>

              <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
                Package Details
              </div>
              <b-row>
                <!-- Waybill No -->
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    class="is_waybill_auto ? '' : 'required'"
                    label="Waybill No"
                    label-for="waybillNo"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Waybill NUmber"
                      vid="Waybill NUmber"
                      :rules="is_waybill_auto ? '' : 'required'"
                    >
                      <b-form-input
                        id="waybillNo"
                        v-model="order.waybill_number"
                        :placeholder="waybillPlaceholder(is_waybill_auto)"
                        :disabled="is_waybill_auto"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <!-- Order No -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Order No"
                    label-for="orderNo"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Order Number"
                      vid="Order Number"
                    >
                      <b-form-input
                        id="orderNo"
                        v-model="order.order_no"
                        placeholder="001"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- COD Amount -->
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    class="required"
                    label="COD Amount"
                    label-for="cod"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="COD"
                      vid="COD"
                      rules="required"
                    >
                      <b-form-input
                        id="cod"
                        v-model="order.cod"
                        type="number"
                        placeholder="Item value + Delivery Charge"
                        maxlength="5"
                        oninput="this.value=this.value.slice(0,this.maxLength)"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <!-- Weight -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    class="required"
                    label="Weight"
                    label-for="weight"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Weight"
                      vid="Weight"
                      rules="required"
                    >
                      <b-input-group>
                        <b-form-input
                          id="weight"
                          v-model="order.weight"
                          type="number"
                          placeholder="1"
                          autocomplete="nope"
                          step=".01"
                          pattern="^\d*(\.\d{0,2})?$"
                          :class="{ 'is-invalid': order.maxWeightError }"
                          @input="checkMaxWeight(order)"
                        />
                        <b-input-group-append is-text>
                          <b>Kg</b>
                        </b-input-group-append>
                      </b-input-group>
                      <small
                        v-if="order.maxWeightError"
                        class="text-danger"
                      >{{ order.maxWeightError }}</small>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
                Customer Details
              </div>
              <b-row>
                <!-- Customer Name -->
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    class="required"
                    label="Customer Name"
                    label-for="customerName"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Customer Name"
                      vid="Customer Name"
                      rules="required"
                    >
                      <b-form-input
                        id="customerName"
                        v-model="order.customer_name"
                        placeholder="Alex"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <!-- Address -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    class="required"
                    label="Address"
                    label-for="address"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Customer Address"
                      vid="Customer Address"
                      rules="required"
                    >
                      <b-form-input
                        id="address"
                        v-model="order.customer_address"
                        placeholder="Colombo 07"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Destination City -->
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    class="required"
                    label="Destination City"
                    label-for="city"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Destination City"
                      vid="Destination City"
                      rules="required"
                    >
                      <v-select
                        v-model="order.destination_city_id"
                        :options="cityOptions"
                        :reduce="option => option.id"
                        label="name"
                        autocomplete="nope"
                        placeholder="Select City"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <!-- Email -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Customer Email"
                      vid="Customer Email"
                      rules="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="order.customer_email"
                        type="email"
                        placeholder="abc@parallax.lk"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Phone No -->
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    class="required"
                    label="Primary Phone No"
                    label-for="customerPhone"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="Customer Phone"
                      vid="Customer Phone"
                      rules="required"
                    >
                      <b-form-input
                        id="customerPhone"
                        v-model="order.customer_phone"
                        type="number"
                        placeholder="0xxxxxxxxx"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="orderSettings.second_phone_number"
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Second Phone No"
                    label-for="secondPhoneNO"
                  >
                    <b-form-input
                      id="secondPhoneNO"
                      v-model="order.customer_secondary_phone"
                      type="number"
                      placeholder="0xxxxxxxxx"
                      autocomplete="nope"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
                Other Details
              </div>
              <b-row>
                <!-- Order Description -->
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    label="Order Description"
                    label-for="description"
                  >
                    <ValidationProvider
                      name="Description"
                      vid="Description"
                      rules=""
                    >
                      <b-form-textarea
                        id="description"
                        v-model="order.description"
                        placeholder="Description"
                        :class="order.description.length >= maxChar ? 'text-danger' : ''"
                        :maxlength="maxChar"
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="order.description.length >= maxChar ? 'bg-danger' : ''"
                      >
                        <span class="char-count">{{ order.description.length }}</span> / {{ maxChar }}
                      </small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <!-- Remarks -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Remarks"
                    label-for="remark"
                  >
                    <ValidationProvider
                      name="Remark"
                      vid="Remark"
                      rules=""
                    >
                      <b-form-textarea
                        id="remark"
                        v-model="order.remark"
                        placeholder="Extra Details"
                        :class="order.remark.length >= maxChar ? 'text-danger' : ''"
                        :maxlength="maxChar"
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="order.remark.length >= maxChar ? 'bg-danger' : ''"
                      >
                        <span class="char-count">{{ order.remark.length }}</span> / {{ maxChar }}
                      </small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

              </b-row>

              <!-- Remove Button -->
              <b-col
                cols="12"
                class="d-flex justify-content-end mb-1"
              >
                <b-button
                  variant="outline-danger"
                  class="mb-1 mt-1 mt-md-2 mr-md-2 mr-xl-4"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>Delete</span>
                </b-button>
              </b-col>
            </b-col>

            <b-col>
              <b-col cols="12">
                <hr>
              </b-col>
              <b-row class="d-flex justify-content-center justify-content-md-end mr-md-2 mr-xl-5 mt-3 mb-1">
                <b-button
                  class="custom-button-color-blur"
                  @click="onClickAdd"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add New</span>
                </b-button>
                &nbsp;
                <b-button
                  class="custom-button-color mr-md-1"
                  type="submit"
                >
                  <feather-icon
                    icon="UploadCloudIcon"
                    class="mr-24"
                  />
                  <span> Submit</span>
                </b-button>
              </b-row>
            </b-col>
          </b-form>
        </ValidationObserver>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BRow, BCol, BButton, BCard, BOverlay, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
// eslint-disable-next-line import/named
import { numberToWordWithTh } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const OrderRepository = RepositoryFactory.get('order')
const MerchantRepository = RepositoryFactory.get('merchant')

export default {
  name: 'BulkOrderCreate',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
    BCard,
    BOverlay,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    orderSettings: {
      type: Object,
      default: null,
    },
    waybillPlaceholder: {
      type: Function,
      default: () => {},
    },
    waybillPattern: {
      type: Function,
      default: () => {},
    },
    patternTitle: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      businesses: [],
      businessAddresses: [],
      merchants: [],
      originCities: [],
      destinationCities: [],
      is_waybill_auto: true,
      merchant_business_id: '',
      merchant_id: '',
      merchant: null,
      loading: false,
      concat: true,
      origin_city_id: '',
      pickupAddress: '',
      maxChar: 190,
      orders: [
        {
          order_no: '',
          customer_name: '',
          customer_address: '',
          customer_phone: '',
          customer_secondary_phone: '',
          customer_email: '',
          destination_city_id: '',
          cod: '',
          weight: '',
          description: '',
          remark: '',
          waybill_number: '',
          typingtimer: null,
          isMaxWeight: false,
          validationState: null,
          validationMessage: '',
        },
      ],
      search: '',
    }
  },
  async mounted() {
    this.loading = true
    await this.getMerchantList()
    await this.getCityList()
    this.loading = false
  },
  methods: {
    numberToWordWithTh,
    onClickAdd() {
      this.orders.push(
        {
          order_no: '',
          customer_name: '',
          customer_address: '',
          customer_phone: '',
          customer_secondary_phone: '',
          customer_email: '',
          destination_city_id: '',
          cod: '',
          weight: '',
          description: '',
          remark: '',
          waybill_number: '',
          defaultPickAddressId: '',
        },
      )
    },
    removeItem(index) {
      this.orders.splice(index, 1)
    },
    async getMerchantList() {
      try {
        this.merchants = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await MerchantRepository.getMerchantListForDropdown()).data.data
        if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchants))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getBusinessList() {
      try {
        this.loading = true
        this.businesses = []
        this.merchant_business_id = null
        this.merchant_id = this.merchant.id
        const { data } = (await MerchantRepository.getMerchantBusinessListDropdown(this.merchant_id)).data
        this.businesses = data
        if (this.businesses.length === 1) {
          this.merchant_business_id = this.businesses[0].id
          await this.getAddress()
        }
        await this.fetchMerchantSettings()
        this.$emit('set-placeholder', this.merchant)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    checkMaxWeight(order) {
      const minWeight = this.orderSettings.weight_range_setting.min_weight
      const maxWeight = this.orderSettings.weight_range_setting.max_weight
      const currentWeight = parseFloat(order.weight)

      if (this.orderSettings.weight_range_setting.enabled === true && (currentWeight < minWeight || currentWeight > maxWeight)) {
        this.$set(order, 'maxWeightError', `The order weight should be between ${minWeight} Kg to ${maxWeight} Kg`)
      } else {
        this.$set(order, 'maxWeightError', null)
      }
    },
    async fetchMerchantSettings() {
      try {
        this.loading = true
        const { data } = (await MerchantRepository.getMerchantBusinessSettingsDetails(this.merchant_id)).data
        this.is_waybill_auto = data.is_waybill_auto
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getCityList() {
      try {
        this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityDropdownListNoFilter(this.search)).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.cityOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getAddress() {
      this.loading = true
      try {
        if (this.merchant_id && this.merchant_business_id) {
          this.pickupAddress = null
          this.businessAddresses = (await MerchantRepository.getbusinessAddresses(this.merchant_id, this.merchant_business_id, this.concat)).data.data
          localStorage.setItem('address', JSON.stringify(this.businessAddresses))
          this.defaultPickAddressId = (this.businessAddresses.filter(address => address.is_default))[0].id
          if (this.defaultPickAddressId) {
            this.pickupAddress = this.defaultPickAddressId
            await this.setDefaultOriginCity()
          }
          const { data } = (await MerchantRepository.getMerchantBusinessDetails(this.merchant_id, this.merchant_business_id)).data
          await this.getCityList(data.business_default_address.city.name)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async setDefaultOriginCity() {
      try {
        this.loading = true
        const cities = JSON.parse(localStorage.getItem('address'))
        // Loop through the array and check for a matching city id
        let cityId = null
        let cityName = null
        cities.forEach(city => {
          if (city.id === this.pickupAddress) {
            cityId = city.city_id
            cityName = city.city_name
          }
        })
        if (cityId) {
          this.origin_city_id = cityId
          this.origin_city_name = cityName
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async onClickSubmit() {
      this.loading = true
      if (this.is_waybill_auto) {
        this.orders.forEach(order => {
          // eslint-disable-next-line no-param-reassign
          delete order.waybill_number
        })
      }
      const payload = {
        general_data: {
          sender_type: 'merchant',
          merchant_id: this.merchant_id,
          merchant_business_id: this.merchant_business_id,
          origin_city_id: this.origin_city_id,
          pickup_address_id: this.pickupAddress,
        },
        order_data: this.orders,
      }
      if (!payload.general_data.origin_city_id) delete payload.general_data.origin_city_id
      try {
        await OrderRepository.createBulkOrder(payload)
        this.showSuccessMessage('Order created successfully')
        this.$router.push({ path: '/operations/all-orders' })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/required.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.custom-button-color-blur {
  background-color: #6da5ff !important;
  border-color: #6da5ff !important;
}
</style>
