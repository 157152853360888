<template>
  <b-overlay
    :opacity="0.5"
    :show="loading"
    rounded="sm"
    :blur="'true'"
  >
    <b-card>
      <ValidationObserver
        ref="createStaffForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          autocomplete="off"
          @submit.prevent="handleSubmit(onClickCreateOrder)"
        >    <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
               Merchant Details
             </div>
          <b-row>
            <!-- Merchants -->
            <b-col
              cols="12"
              md="5"
              class="ml-md-1 mr-lg-4"
            >
              <b-form-group
                class="required"
                label="Merchant"
                label-cols-lg="4"
                label-for="h-merchantBusiness"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-merchantBusiness"
                  vid="h-c-merchantBusiness"
                  rules="required"
                >
                  <v-select
                    v-model="merchant"
                    :options="merchants"
                    :reduce="option => option"
                    label="name"
                    placeholder="Select Merchant"
                    @input="getMerchantBusinessList()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Merchant Business"
                label-cols-lg="4"
                label-for="h-merchantBusiness"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-merchantBusiness"
                  vid="h-c-merchantBusiness"
                  rules="required"
                >
                  <v-select
                    v-model="merchant_business_id"
                    :options="businesses"
                    :reduce="option => option.id"
                    label="business_name"
                    placeholder="Select Business profile"
                    @input="!orderSettings.origin_city_freeze ? getAddress() : () =>{}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
            Package Details
          </div>
          <!-- Waybill No -->
          <b-row class="mt-2">
            <b-col
              cols="12"
              md="5"
              class="ml-md-1 mr-lg-4"
            >
              <b-form-group
                :class="is_waybill_auto ? '' : 'required'"
                label="Waybill No"
                label-cols-lg="4"
                label-for="h-waybillNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-waybillNo"
                  vid="h-c-waybillNo"
                  :rules="is_waybill_auto ? '' : 'required'"
                >
                  <b-form-input
                    id="waybillNo"
                    v-model="form.waybill_number"
                    :placeholder="waybillPlaceholder(is_waybill_auto)"
                    :disabled="is_waybill_auto"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!-- Order No -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Order No"
                label-cols-lg="4"
                label-for="h-orderNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-orderNo"
                  vid="h-c-orderNo"
                >
                  <b-form-input
                    id="orderNo"
                    v-model="form.order_no"
                    placeholder="001"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- COD Amount -->
            <b-col
              cols="12"
              md="5"
              class="ml-md-1 mr-lg-4"
            >
              <b-form-group
                class="required"
                label="COD Amount"
                label-cols-lg="4"
                label-for="cod"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-cod"
                  vid="h-c-cod"
                  rules="required"
                >
                  <b-form-input
                    id="cod"
                    v-model="form.cod"
                    type="number"
                    placeholder="Item value + Delivery Charge"
                    maxlength="5"
                    oninput="this.value=this.value.slice(0,this.maxLength)"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <!-- Weight -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Weight"
                class="required"
                label-cols-lg="4"
                label-for="weight"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-weight"
                  vid="h-c-weight"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="weight"
                      ref="weightInput"
                      v-model="form.weight"
                      type="number"
                      placeholder="1"
                      autocomplete="nope"
                      step=".01"
                      pattern="^\d*(\.\d{0,2})?$"
                      @input="checkWeightRange"
                    />
                    <b-input-group-append is-text>
                      <b>Kg</b>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
            Customer Details
          </div>
          <!-- Customer Name -->
          <b-row>
            <b-col
              cols="12"
              md="5"
              class="ml-md-1 mr-lg-4"
            >
              <b-form-group
                class="required"
                label="Customer Name"
                label-cols-lg="4"
                label-for="customerName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-customerName"
                  vid="h-c-customerName"
                  rules="required"
                >
                  <b-form-input
                    id="customerName"
                    v-model="form.customer_name"
                    placeholder="Alex"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <!-- Address -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Address"
                label-cols-lg="4"
                label-for="address"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-address"
                  vid="h-c-address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="form.customer_address"
                    placeholder="Colombo 07"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!--primary Phone No -->
            <b-col
              cols="12"
              md="5"
              class="ml-md-1 mr-lg-4"
            >
              <b-form-group
                class="required"
                label="Primary Phone No"
                label-cols-lg="4"
                label-for="customerPhone"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-customerPhone"
                  vid="h-c-customerPhone"
                  rules="required"
                >
                  <b-form-input
                    id="customerPhone"
                    v-model="form.customer_phone"
                    type="number"
                    placeholder="0xxxxxxxxx"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Email"
                label-cols-lg="4"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="form.customer_email"
                  type="email"
                  placeholder="abc@parallax.lk"
                  autocomplete="nope"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!--second Phone No -->
            <b-col
              v-if="orderSettings.second_phone_number"
              cols="12"
              md="5"
              class="ml-md-1 mr-lg-4"
            >
              <b-form-group
                label="Second Phone No"
                label-cols-lg="4"
                label-for="customerPhone"
              >
                <b-form-input
                  id="customerPhone"
                  v-model="form.customer_secondary_phone"
                  type="number"
                  placeholder="0xxxxxxxxx"
                  autocomplete="nope"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
            {{ !orderSettings.origin_city_freeze ? 'Origin & Destination' : 'Destination' }}
          </div>
          <b-row>
            <!-- Pickup Address -->
            <b-col
              v-if="!orderSettings.origin_city_freeze"
              cols="12"
              md="5"
              class="ml-md-1 mr-lg-4"
            >
              <b-form-group
                class="required"
                label="Pickup Address"
                label-cols-lg="4"
                label-for="pickupAddress"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="pickupaddress"
                  rules="required"
                >
                  <v-select
                    v-model="form.pickup_address"
                    class="pickup"
                    :options="businessAddresses"
                    :reduce="option => option.id"
                    label="pickup_address"
                    autocomplete="nope"
                    placeholder="Select Pickup Address"
                    @input="setDefaultOriginCity()"
                  >
                    <template v-slot:option="option">
                      {{ option.pickup_address }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!-- Destination City -->
            <b-col
              cols="12"
              :md="!orderSettings.origin_city_freeze ? '6' : '5'"
              :class="!orderSettings.origin_city_freeze ? '' : 'ml-md-1 mr-lg-4'"
            >
              <b-form-group
                class="required"
                label="Destination City"
                label-cols-lg="4"
                label-for="city"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-city"
                  vid="h-c-city"
                  rules="required"
                >
                  <v-select
                    v-model="form.destination_city_id"
                    :options="cityOptions"
                    :reduce="option => option.id"
                    label="name"
                    autocomplete="nope"
                    placeholder="Select Destination City"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Origin City -->
            <b-col
              v-if="!orderSettings.origin_city_freeze"
              cols="12"
              md="5"
              class="ml-md-1 mr-lg-4"
            >
              <b-form-group
                class="required"
                label=" Origin City"
                label-cols-lg="4"
                label-for="h-originCity"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-originCity"
                  vid="h-c-originCity"
                  rules="required"
                >
                  <b-form-input
                    v-model="origin_city_id"
                    :hidden="true"
                  />
                  <b-form-input
                    v-model="origin_city_name"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
            Other Details
          </div>
          <b-row class="mb-2">
            <!-- Order Description -->
            <b-col
              cols="12"
              md="5"
              class="ml-md-1 mr-lg-4"
            >
              <b-form-group
                label="Order Description"
                label-cols-lg="4"
                label-for="description"
              >
                <ValidationProvider
                  name="h-c-description"
                  vid="h-c-description"
                  rules=""
                >
                  <b-form-textarea
                    id="description"
                    v-model="form.description"
                    placeholder="Description"
                    :class="form.description.length >= maxChar ? 'text-danger' : ''"
                    :maxlength="maxChar"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="form.description.length >= maxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ form.description.length }}</span> / {{ maxChar }}
                  </small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <!-- Remarks -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Remarks"
                label-cols-lg="4"
                label-for="remark"
              >
                <ValidationProvider
                  name="h-c-remark"
                  vid="h-c-remark"
                  rules=""
                >
                  <b-form-textarea
                    id="remark"
                    v-model="form.remark"
                    placeholder="Extra Details"
                    :class="form.remark.length >= maxChar ? 'text-danger' : ''"
                    :maxlength="maxChar"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="form.remark.length >= maxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ form.remark.length }}</span> / {{ maxChar }}
                  </small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end">
            <b-row class="justify-content-md-end mb-1 mr-md-2 mr-xl-4">
              <!-- submit and reset -->
              <b-col>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  class="float-right mr-md-1"
                >
                  Reset
                </b-button>
                <b-button
                  class="mr-1 float-right custom-button-color"
                  type="submit"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </ValidationObserver>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BRow, BCol, BButton, BCard, BOverlay, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const OrderRepository = RepositoryFactory.get('order')
const MerchantRepository = RepositoryFactory.get('merchant')

export default {
  name: 'SingleOrderCreate',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
    BCard,
    BOverlay,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    orderSettings: {
      type: Object,
      default: null,
    },
    waybillPlaceholder: {
      type: Function,
      default: () => {},
    },
    waybillPattern: {
      type: Function,
      default: () => {},
    },
    patternTitle: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      businesses: [],
      businessAddresses: [],
      merchants: [],
      cityOptions: [],
      is_waybill_auto: true,
      merchant_business_id: '',
      merchant_id: '',
      merchant: {},
      loading: false,
      concat: true,
      origin_city_id: '',
      defaultPickAddressId: '',
      maxChar: 190,
      form: {
        order_no: '',
        customer_name: '',
        customer_address: '',
        customer_phone: '',
        customer_secondary_phone: '',
        customer_email: '',
        destination_city_id: '',
        cod: '',
        weight: '',
        description: '',
        remark: '',
        waybill_number: '',
        typingtimer: null,
        isMaxWeight: false,
        validationState: null,
        validationMessage: '',
      },
    }
  },
  async mounted() {
    this.loading = true
    await this.getCityList()
    await this.getMerchantList()
    this.loading = false
  },
  methods: {
    async getMerchantBusinessList() {
      try {
        this.loading = true
        this.businesses = []
        this.merchant_business_id = null
        this.merchant_id = this.merchant.id
        const { data } = (await MerchantRepository.getMerchantBusinessListDropdown(this.merchant_id)).data
        this.businesses = data
        if (this.businesses.length === 1) {
          this.merchant_business_id = this.businesses[0].id
          await this.getAddress()
        }
        await this.fetchMerchantSettings()
        this.$emit('set-placeholder', this.merchant)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    checkWeightRange() {
      const minWeight = this.orderSettings.weight_range_setting.min_weight
      const maxWeight = this.orderSettings.weight_range_setting.max_weight
      const currentWeight = parseFloat(this.form.weight)
      if (this.orderSettings.weight_range_setting.enabled === true && (currentWeight < minWeight || currentWeight > maxWeight)) {
        this.validationState = false
        this.validationMessage = `The order weight should be between ${minWeight} Kg to ${maxWeight} Kg`
        this.isMaxWeight = true
      } else {
        this.validationState = true
        this.validationMessage = ''
        this.isMaxWeight = false
      }
      this.$refs.weightInput.setCustomValidity(this.validationMessage)
    },
    async fetchMerchantSettings() {
      try {
        this.loading = true
        const { data } = (await MerchantRepository.getMerchantBusinessSettingsDetails(this.merchant_id)).data
        this.is_waybill_auto = data.is_waybill_auto
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getMerchantList() {
      try {
        this.merchants = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await MerchantRepository.getMerchantListForDropdown()).data.data
        if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchants))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getCityList() {
      try {
        this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityDropdownListNoFilter(this.search)).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.cityOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getBusinessList() {
      try {
        const { data } = (await ResourceRepository.getMerchantBusinessNoPagination()).data
        this.businesses = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getAddress() {
      try {
        this.loading = true
        if (this.merchant_id && this.merchant_business_id) {
          this.form.pickup_address = null
          this.businessAddresses = (await MerchantRepository.getbusinessAddresses(this.merchant_id, this.merchant_business_id, this.concat)).data.data
          localStorage.setItem('address', JSON.stringify(this.businessAddresses))
          this.defaultPickAddressId = (this.businessAddresses.filter(address => address.is_default))[0].id
          if (this.defaultPickAddressId) {
            this.form.pickup_address = this.defaultPickAddressId
            await this.setDefaultOriginCity()
          }
          // const { data } = (await MerchantRepository.getMerchantBusinessDetails(this.merchant_id, this.merchant_business_id)).data
          // await this.getCityList(data.business_default_address.city.name)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async setDefaultOriginCity() {
      try {
        this.loading = true
        const cities = JSON.parse(localStorage.getItem('address'))
        // Loop through the array and check for a matching city id
        let cityId = null
        let cityName = null
        cities.forEach(city => {
          if (city.id === this.form.pickup_address) {
            cityId = city.city_id
            cityName = city.city_name
          }
        })
        if (cityId) {
          this.origin_city_id = cityId
          this.origin_city_name = cityName
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async onClickCreateOrder() {
      this.loading = true
      const payload = {
        general_data: {
          sender_type: 'merchant',
          merchant_id: this.merchant_id,
          merchant_business_id: this.merchant_business_id,
          origin_city_id: this.origin_city_id,
          pickup_address_id: this.form.pickup_address,
        },
        order_data: [],
      }
      payload.order_data.push({
        waybill_number: this.form.waybill_number,
        order_no: this.form.order_no,
        customer_name: this.form.customer_name,
        customer_address: this.form.customer_address,
        customer_phone: this.form.customer_phone,
        customer_secondary_phone: this.form.customer_secondary_phone,
        customer_email: this.form.customer_email,
        destination_city_id: this.form.destination_city_id,
        cod: this.form.cod,
        weight: this.form.weight,
        description: this.form.description,
        remark: this.form.remark || null,
      })
      if (!payload.general_data.origin_city_id) delete payload.general_data.origin_city_id
      try {
        await OrderRepository.createSingleOrder(payload)
        this.showSuccessMessage('Order created successfully')
        this.$router.push({ path: '/operations/all-orders' })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/required.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
