<template>
  <b-overlay
    :opacity="0.5"
    :show="loading"
    rounded="sm"
    :blur="'true'"
  >
    <b-card>
      <ValidationObserver
        ref="createStaffForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          autocomplete="off"
          @submit.prevent="handleSubmit(onClickCreateOrder)"
        >
          <div>
            <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
              Sender Details
            </div>
            <!-- Sender Name -->
            <b-row>
              <b-col
                cols="12"
                md="5"
                class="ml-md-1 mr-lg-4"
              >
                <b-form-group
                  class="required"
                  label="Sender Name"
                  label-cols-lg="4"
                  label-for="senderName"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-sender-name"
                    vid="h-c-sender-name"
                    rules="required"
                  >
                    <v-select
                      v-model="walking_customer_id"
                      :options="customers"
                      :reduce="option => option.id"
                      label="name"
                      autocomplete="nope"
                      placeholder="Select Sender Name"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  class="required"
                  label=" Sender City"
                  label-cols-lg="4"
                  label-for="h-originCity"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-originCity"
                    vid="h-c-originCity"
                    rules="required"
                  >
                    <v-select
                      v-model="origin_city_id"
                      :options="cityOptions"
                      :reduce="option => option.id"
                      label="name"
                      autocomplete="nope"
                      placeholder="Select Sender City"
                      @search="(search, loading) => {
                        loading(true)
                        getCityList(search, 'origin').then(() => loading(false))
                      }"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
              Receiver Details
            </div>
            <!-- Sender Name -->
            <b-row>
              <b-col
                cols="12"
                md="5"
                class="ml-md-1 mr-lg-4"
              >
                <b-form-group
                  class="required"
                  label="Receiver Name"
                  label-cols-lg="4"
                  label-for="receiverName"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-receiverName"
                    vid="h-c-receiverName"
                    rules="required"
                  >
                    <b-form-input
                      id="receiverName"
                      v-model="form.customer_name"
                      placeholder="Alex"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- Address -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  class="required"
                  label="Address"
                  label-cols-lg="4"
                  label-for="address"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-address"
                    vid="h-c-address"
                    rules="required"
                  >
                    <b-form-input
                      id="address"
                      v-model="form.customer_address"
                      placeholder="Colombo 07"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Phone No -->
              <b-col
                cols="12"
                md="5"
                class="ml-md-1 mr-lg-4"
              >
                <b-form-group
                  class="required"
                  label="Phone No"
                  label-cols-lg="4"
                  label-for="senderPhone"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-senderPhone"
                    vid="h-c-senderPhone"
                    rules="required"
                  >
                    <b-form-input
                      id="senderPhone"
                      v-model="form.customer_phone"
                      type="number"
                      placeholder="0xxxxxxxxx"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- id -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  class="required"
                  label="Receiver ID"
                  label-cols-lg="4"
                  label-for="receiver_id"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-receiverPhone"
                    vid="h-c-receiverPhone"
                    rules="required"
                  >
                    <b-form-input
                      id="receiver_id"
                      v-model="form.customer_email"
                      type="text"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- To City -->
              <b-col
                cols="12"
                md="5"
                class="ml-md-1 mr-lg-4"
              >
                <b-form-group
                  class="required"
                  label=" Receiver City"
                  label-cols-lg="4"
                  label-for="h-destinationCity"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-destinationity"
                    vid="h-c-destinationCity"
                    rules="required"
                  >
                    <v-select
                      v-model="form.destination_city_id"
                      :options="cityOptions"
                      :reduce="option => option.id"
                      label="name"
                      autocomplete="nope"
                      placeholder="Select Receiver City"
                      @search="(search, loading) => {
                        loading(true)
                        getCityList(search, 'destination').then(() => loading(false))
                      }"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
              Order Details
            </div>
            <!-- Waybill No -->
            <b-row class="mt-2">
              <b-col
                cols="12"
                md="5"
                class="ml-md-1 mr-lg-4"
              >
                <b-form-group
                  :class="is_waybill_auto ? '' : 'required'"
                  label="Waybill No"
                  label-cols-lg="4"
                  label-for="h-waybillNo"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-waybillNo"
                    vid="h-c-waybillNo"
                    :rules="is_waybill_auto ? '' : 'required'"
                  >
                    <b-form-input
                      id="waybillNo"
                      v-model="form.waybill_number"
                      :placeholder="is_waybill_auto ? 'Auto Generate' : ( orderSettings.waybill_setting.manual_waybill_prefix && orderSettings.waybill_setting.digit_count ) ? `${orderSettings.waybill_setting.manual_waybill_prefix}${'0'.repeat(orderSettings.waybill_setting.digit_count)}`:'XXxxxxxx'"
                      :disabled="is_waybill_auto"
                      :pattern="!is_waybill_auto? (orderSettings.waybill_setting.manual_waybill_prefix && orderSettings.waybill_setting.digit_count ? (`${orderSettings.waybill_setting.manual_waybill_prefix}[0-9]{${orderSettings.waybill_setting.digit_count}}`) : null) : null"
                      :title="!is_waybill_auto? (orderSettings.waybill_setting.manual_waybill_prefix && orderSettings.waybill_setting.digit_count ? (`The waybill number must start with ${orderSettings.waybill_setting.manual_waybill_prefix} and contain ${orderSettings.waybill_setting.digit_count} trailing ${orderSettings.waybill_setting.digit_count == 1 ? 'digit':'digits'}`) : null) : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- Order No -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Order No"
                  label-cols-lg="4"
                  label-for="h-orderNo"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-orderNo"
                    vid="h-c-orderNo"
                  >
                    <b-form-input
                      id="orderNo"
                      v-model="form.order_no"
                      placeholder="001"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Weight -->
              <b-col
                cols="12"
                md="5"
                class="ml-md-1 mr-lg-4"
              >
                <b-form-group
                  class="required"
                  label="Weight"
                  label-cols-lg="4"
                  label-for="weight"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-weight"
                    vid="h-c-weight"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        id="weight"
                        v-model="form.weight"
                        type="number"
                        placeholder="1"
                        autocomplete="nope"
                      />
                      <b-input-group-append is-text>
                        <b>Kg</b>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  class="required"
                  label="Advance"
                  label-cols-lg="4"
                  label-for="advance"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="h-c-advance"
                    vid="h-c-advance"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        id="weight"
                        v-model="form.collected_cod"
                        type="number"
                        placeholder="1"
                        autocomplete="nope"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <div class="mt-2 mb-2 ml-md-1 font-weight-bolder">
              Other Details
            </div>
            <b-row class="mb-2">
              <!-- Order Description -->
              <b-col
                cols="12"
                md="5"
                class="ml-md-1 mr-lg-4"
              >
                <b-form-group
                  label="Order Description"
                  label-cols-lg="4"
                  label-for="description"
                >
                  <ValidationProvider
                    name="h-c-description"
                    vid="h-c-description"
                    rules=""
                  >
                    <b-form-textarea
                      id="description"
                      v-model="form.description"
                      placeholder="Description"
                      :class="form.description.length >= maxChar ? 'text-danger' : ''"
                      :maxlength="maxChar"
                    />
                    <small
                      class="textarea-counter-value float-right"
                      :class="form.description.length >= maxChar ? 'bg-danger' : ''"
                    >
                      <span class="char-count">{{ form.description.length }}</span> / {{ maxChar }}
                    </small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- Remarks -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Remarks"
                  label-cols-lg="4"
                  label-for="remark"
                >
                  <ValidationProvider
                    name="h-c-remark"
                    vid="h-c-remark"
                    rules=""
                  >
                    <b-form-textarea
                      id="remark"
                      v-model="form.remark"
                      placeholder="Extra Details"
                      :class="form.remark.length >= maxChar ? 'text-danger' : ''"
                      :maxlength="maxChar"
                    />
                    <small
                      class="textarea-counter-value float-right"
                      :class="form.remark.length >= maxChar ? 'bg-danger' : ''"
                    >
                      <span class="char-count">{{ form.remark.length }}</span> / {{ maxChar }}
                    </small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <div>
              <b-row>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(66, 133, 245, 0.15)'"
                    variant="outline-primary"
                    class="ml-md-1 mt-1 mr-md-4 mobile-width-button"
                    @click="calculateDeliveryCharge"
                  >
                    Calculate The Delivery Charge
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <!-- Delivery charge -->
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mt-2 mr-lg-4"
                >
                  <b-form-group
                    label="Delivery Charge"
                    label-cols-lg="4"
                    label-for="delivery_charge"
                  >
                    <b-input-group>
                      <b-form-input
                        id="delivery_charge"
                        v-model="deliveryCharge"
                        type="number"
                        disabled
                        autocomplete="nope"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Advance -->
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    label="Advance From Sender"
                    label-cols-lg="4"
                    label-for="advance"
                  >
                    <b-input-group>
                      <b-form-input
                        id="advance"
                        v-model="form.collected_cod"
                        type="number"
                        placeholder="00"
                        autocomplete="nope"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Balance -->
                <b-col
                  cols="12"
                  md="5"
                  class="ml-md-1 mr-lg-4"
                >
                  <b-form-group
                    label="Balance Payment"
                    label-cols-lg="4"
                    label-for="balance"
                  >
                    <b-input-group>
                      <b-form-input
                        id="balance"
                        v-model="balance"
                        type="number"
                        placeholder="00"
                        disabled
                        autocomplete="nope"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="d-flex justify-content-center justify-content-md-end">
            <b-row class="justify-content-md-end mb-1 mr-md-2 mr-xl-4">
              <!-- submit and reset -->
              <b-col>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  class="float-right mr-md-1"
                >
                  Reset
                </b-button>
                <b-button
                  class="mr-1 float-right"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </ValidationObserver>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BRow, BCol, BButton, BCard, BOverlay, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const OrderRepository = RepositoryFactory.get('order')
const WalkingCustomerRepository = RepositoryFactory.get('walkingCustomer')

export default {
  name: 'SingleOrderCreate',
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
    BCard,
    BOverlay,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    orderSettings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      originCities: [],
      destinationCities: [],
      warehouses: [],
      is_waybill_auto: true,
      calculated_charge: '',
      loading: false,
      origin_city_id: '',
      origin_warehouse_id: '',
      form: {
        remark: '',
        description: '',
      },
      search: '',
      customers: [],
      deliveryCharge: null,
      balance: null,
      maxChar: 190,
    }
  },
  async mounted() {
    this.loading = true
    await this.getCityList()
    await this.getWalkingCustomerList()
    this.loading = false
  },
  methods: {
    async getWalkingCustomerList() {
      this.customers = localStorage.getItem('walking_customer_list') ? JSON.parse(localStorage.getItem('walking_customer_list')) : (await WalkingCustomerRepository.getWalkingCustomerList()).data.data
      if (!localStorage.getItem('walking_customer_list')) localStorage.setItem('walking_customer_list', JSON.stringify(this.customers))
    },
    async getCityList() {
      try {
        this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityDropdownListNoFilter(this.search)).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.cityOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async calculateDeliveryCharge() {
      try {
        const payload = {
          walking_customer_id: this.walking_customer_id,
          destination_city_id: this.form.destination_city_id,
          origin_city_id: this.origin_city_id,
          weight: this.form.weight,
        }
        const { data } = (await OrderRepository.calculateDeliveryCharge(payload)).data
        this.deliveryCharge = data
        this.balance = Number(this.deliveryCharge) - Number(this.form.collected_cod)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickCreateOrder() {
      this.loading = true
      const payload = {
        general_data: {
          sender_type: 'walking',
          walking_customer_id: this.walking_customer_id,
          origin_city_id: this.origin_city_id,
          origin_warehouse_id: this.origin_warehouse_id,
        },
        order_data: [],
      }
      payload.order_data.push({
        waybill_number: this.form.waybill_number,
        order_no: this.form.order_no,
        customer_name: this.form.customer_name,
        customer_address: this.form.customer_address,
        customer_phone: this.form.customer_phone,
        customer_email: this.form.customer_email,
        destination_city_id: this.form.destination_city_id,
        cod: this.form.cod || 0,
        weight: this.form.weight,
        description: this.form.description,
        remark: this.form.remark || null,
        collected_cod: this.form.collected_cod,
      })
      try {
        await OrderRepository.createSingleOrderWalkingCustomer(payload)
        this.showSuccessMessage('Order created successfully')
        this.$router.push({ path: '/operations/all-orders' })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/required.scss';
</style>
