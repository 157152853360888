<template>
  <div>
    <div
      v-can="'create-walking-orders'"
      class="d-flex justify-content-end"
    >
      <b-form-checkbox
        v-model="is_walkInCustomer"
        :checked="is_walkInCustomer"
        name="check-button"
        switch
        inline
        class="mb-1"
      >
        <span
          :class="is_walkInCustomer? '' : 'text-muted' "
        >
          Walk In Customer
        </span>
      </b-form-checkbox>
    </div>
    <b-tabs ref="orderCreateTabBar">
      <b-tab
        lazy
        title="Single Upload"
      >
        <walking-customer-single-order
          v-if="is_walkInCustomer"
        />
        <single-order-create
          v-else
          :order-settings="orderSettings"
          :waybill-placeholder="waybillPlaceholder"
          :waybill-pattern="waybillPattern"
          :pattern-title="patternTitle"
          @set-placeholder="fetchAssignedWaybillSeries"
        />
      </b-tab>
      <b-tab
        v-if="!is_walkInCustomer"
        lazy
        title="Bulk Upload"
      >
        <bulk-order-create
          :order-settings="orderSettings"
          :waybill-placeholder="waybillPlaceholder"
          :waybill-pattern="waybillPattern"
          :pattern-title="patternTitle"
          @set-placeholder="fetchAssignedWaybillSeries"
        />
      </b-tab>
      <b-tab
        v-if="!is_walkInCustomer"
        lazy
        title="Excel Upload"
      >
        <advance-excel-order-create
          :order-settings="orderSettings"
          :waybill-placeholder="waybillPlaceholder"
          :waybill-pattern="waybillPattern"
          :pattern-title="patternTitle"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BFormCheckbox,
} from 'bootstrap-vue'
import SingleOrderCreate from '@/views/order/create/SingleOrderCreate.vue'
import BulkOrderCreate from '@/views/order/create/BulkOrderCreate.vue'
import AdvanceExcelOrderCreate from '@/views/order/create/AdvanceExcelOrderCreate.vue'
import WalkingCustomerSingleOrder from '@/views/walkingCustomer/orderCreate/SingleOrderCreate.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const OrderSettingsRepository = RepositoryFactory.get('orderSetting')
const waybillSettingsRepository = RepositoryFactory.get('waybillSetting')

export default {
  name: 'OrderCreateHome',
  components: {
    SingleOrderCreate,
    BulkOrderCreate,
    AdvanceExcelOrderCreate,
    WalkingCustomerSingleOrder,
    BTab,
    BTabs,
    BFormCheckbox,
  },
  data() {
    return {
      waybillSeriesPreview: [],
      orderSettings: {},
      is_walkInCustomer: false,
      manualWaybillPrefix: '',
      manualWaybillPrefix2: '',
      manualWaybillSuffix: '',
      waybillDigitCount: 0,
    }
  },
  async mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('orderCreateTabBar', 'orderCreateTabIndex')
    await this.readOrderSetting()
  },
  methods: {
    async fetchAssignedWaybillSeries(merchant) {
      this.loading = true
      try {
        const { data } = (await waybillSettingsRepository.fetchAssignedWaybillSeries(merchant.id))
        const assignedWaybillSeries = data.data
        const manualWaybillSeries = assignedWaybillSeries.filter(n => n.waybill_series.is_auto === false)
        this.waybillSeriesPreview = manualWaybillSeries.map(n => n.waybill_series.preview)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async readOrderSetting() {
      this.loading = true
      try {
        this.orderSettings = localStorage.getItem('order_setting') ? JSON.parse(localStorage.getItem('order_setting')) : (await OrderSettingsRepository.getOrderSettingsResource()).data.data
        this.manualWaybillPrefix = this.orderSettings.waybill_setting.manual_waybill_prefix
        this.manualWaybillPrefix2 = this.orderSettings.waybill_setting.manual_waybill_prefix_2
        this.waybillDigitCount = this.orderSettings.waybill_setting.digit_count
        if (this.orderSettings.waybill_setting.suffix_enabled) this.manualWaybillSuffix = `${this.orderSettings.waybill_setting.manual_waybill_suffix}`
        if (!localStorage.getItem('order_setting')) localStorage.setItem('order_setting', JSON.stringify(this.orderSettings))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    waybillPlaceholder(autoWaybill) {
      let placeholder
      if (!autoWaybill) {
        placeholder = 'XXxxxxxx'
        if (this.waybillSeriesPreview.length !== 0) {
          placeholder = this.waybillSeriesPreview.join('/')
        }
      } else {
        placeholder = 'Auto Generate'
      }
      return placeholder
    },
    waybillPattern(autoWaybill) {
      let pattern = ''
      if (!autoWaybill) {
        if (this.manualWaybillPrefix && this.waybillDigitCount) {
          pattern = `${this.manualWaybillPrefix}[0-9]{${this.waybillDigitCount}}${this.manualWaybillSuffix}`
          if (this.manualWaybillPrefix2) {
            pattern = `${pattern}|${this.manualWaybillPrefix2}[0-9]{${this.waybillDigitCount}}${this.manualWaybillSuffix}`
          }
        }
      }
      return pattern || null
    },
    patternTitle(autoWaybill) {
      let title = ''
      if (!autoWaybill) {
        if (this.manualWaybillPrefix && this.waybillDigitCount) {
          title = `The waybill number must start with ${this.manualWaybillPrefix} and contain ${this.waybillDigitCount} trailing ${this.orderSettings.waybill_setting.digit_count === 1 ? 'digit' : 'digits'}`
          if (this.manualWaybillPrefix2) {
            title = `The waybill number must start with ${this.manualWaybillPrefix} or ${this.manualWaybillPrefix2} and contain ${this.waybillDigitCount} trailing ${this.orderSettings.waybill_setting.digit_count === 1 ? 'digit' : 'digits'}`
          }
        }
        if (this.manualWaybillSuffix) title = `${title}. Additionally the waybill must end with ${this.manualWaybillSuffix}`
      }
      return title || null
    },
  },
}
</script>

<style scoped>

</style>
