<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="light"
      opacity="0.30"
      blur="10px"
      rounded="sm"
      class="mt-2 mb-2"
    >
      <b-row class="justify-content-md-end justify-content-center">
        <!-- submit and reset -->
        <b-col
          cols="12"
          md="3"
          class="mb-2 mb-md-0"
        >
          <import-excel :on-success="loadDataInTable" />
        </b-col>
        <b-col class="d-flex justify-content-md-end justify-content-center">
          <b-button
            v-if="!orderSettings.second_phone_number"
            :href="excelTemplateExists ? excelTemplateUrl : defaultExcelTemplate"
            class="mr-md-1 float-right"
            type="submit"
            variant="primary"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            Download Excel Template
          </b-button>
          <b-button
            v-else
            :href="excelTemplateExists ? excelTemplateUrl : defaultSecondPhoneNumberExcelTemplate"
            class="mr-md-1 float-right"
            type="submit"
            variant="primary"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            Download Excel Template
          </b-button>
        </b-col>
      </b-row>

      <ValidationObserver
        ref="createExcelBulkUploadForm"
        slim
      >
        <b-form
          ref="form"
          class="repeater-form"
          autocomplete="off"
        >
          <b-row class="mt-4 mb-1">
            <!-- Merchants -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                class="required"
                label="Merchant"
                label-for="merchantBusiness"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Merchant"
                  vid="Merchant"
                  rules="required"
                >
                  <v-select
                    v-model="merchant"
                    :options="merchants"
                    :reduce="option => option"
                    label="name"
                    placeholder="Select Merchant"
                    @input="getBusinessList()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!-- Business Profile -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                class="required"
                label="Business Profile"
                label-for="h-merchantBusiness"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-merchantBusiness"
                  vid="h-merchantBusiness"
                  rules="required"
                >
                  <v-select
                    v-model="merchant_business_id"
                    :options="businesses"
                    :reduce="option => option.id"
                    label="business_name"
                    placeholder="Select Business profile"
                    @input="!orderSettings.origin_city_freeze ? setDefaultOriginCity() : () =>{}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <!-- Origin City -->
            <b-col
              v-if="!orderSettings.origin_city_freeze"
              cols="12"
              md="3"
            >
              <b-form-group
                class="required"
                label=" Origin City"
                label-for="h-originCity"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-originCity"
                  vid="h-originCity"
                  rules="required"
                >
                  <v-select
                    v-model="origin_city_id"
                    :options="cities"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Origin City"
                    @search="(search, loading) => {
                      loading(true)
                      getCityList(search).then(() => loading(false))
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-start mb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mb-1"
              variant="primary"
              @click="newRecord"
            >
              Add Row
            </b-button>
          </div>
          <vue-excel-editor
            :key="is_waybill_auto"
            ref="grid"
            v-model="tableData"
            :localized-label="myLabels"
            :free-select="true"
            enter-to-south
            new-if-bottom
            no-header-edit
            :page="10"
          >
            <vue-excel-column
              field="waybill_number"
              label="WAYBILL NUMBER"
              type="string"
              height="20px"
              width="150px"
              :invisible="is_waybill_auto"
            />
            <vue-excel-column
              field="order_no"
              label="ORDER NUMBER"
              type="string"
              height="20px"
              width="150px"
            />
            <vue-excel-column
              field="customer_name"
              label="CUSTOMER NAME"
              type="string"
              height="20px"
              width="150px"
              :validate="validData"
            />
            <vue-excel-column
              field="customer_phone"
              label="PRIMARY PHONE NO"
              type="number"
              height="20px"
              width="150px"
              :validate="validData"
            />
            <vue-excel-column
              field="customer_secondary_phone"
              label="SECOND PHONE NO"
              type="number"
              height="20px"
              width="150px"
              :invisible="!orderSettings.second_phone_number"
            />
            <vue-excel-column
              field="customer_address"
              label="CUSTOMER ADDRESS"
              type="string"
              height="20px"
              width="450px"
              :validate="validData"
            />
            <vue-excel-column
              field="customer_email"
              label="CUSTOMER EMAIL"
              type="string"
              height="20px"
              width="150px"
              :validate="validEmail"
            />
            <vue-excel-column
              field="cod"
              label="COD"
              type="string"
              height="20px"
              width="150px"
              :validate="validData"
            />
            <vue-excel-column
              field="destination_city_id"
              label="DESTINATION CITY"
              type="map"
              height="20px"
              width="250px"
              :options="destinationCityOptions"
              :validate="validData"
            />
            <vue-excel-column
              field="weight"
              label="WEIGHT"
              type="string"
              height="20px"
              width="150px"
              :validate="validWeight"
            />
            <vue-excel-column
              field="description"
              label="DESCRIPTION"
              type="string"
              height="20px"
              width="150px"
              :validate="validLengthDes"
            />
            <vue-excel-column
              field="remark"
              label="REMARK"
              type="string"
              height="20px"
              width="150px"
              :validate="validLength"
            />
          </vue-excel-editor>
          <div class="d-flex justify-content-center justify-content-md-start">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-2 mt-md-1 mobile-width-button"
              @click="delRecord(selectIndexRecods)"
            > Delete Row
            </b-button>
          </div>
          <b-row
            v-if="errors.length > 0"
            class="mt-4"
          >
            <b-col>
              <b-table
                striped
                hover
                responsive
                :items="errors"
                :fields="errorFields"
              />
            </b-col>
          </b-row>
          <b-row class="justify-content-md-end justify-content-center">
            <!-- submit and reset -->
            <!-- <b-form-textarea
              v-model="clipboardData"
              class="m-1"
              placeholder="You can paste multiple entries into this field to load data into the table by clicking the load icon after pasting the entries. Ensure that the data is copied along with the table headers for accurate processing."
            />
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Tooltip on top!'"
              class="m-1 p-1"
              @click="loadFromClipboard"
            >
              Load
            </b-button> -->
            <JsonExcel
              v-if="tableData.length > 0"
              class="btn p-0"
              :data="tableData"
              :fields="json_fields"
              type="csv"
              :escapeCsv="false"
              :name="`All Orders.xls`"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="my-1"
                variant="success"
              > Download Excel
              </b-button>
            </JsonExcel>
            <b-button
              class="m-1 mobile-width-button"
              type="submit"
              variant="primary"
              :disabled="loading || tableData.length === 0"
              @click="onClickSubmit"
            >
              Submit
            </b-button>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormGroup, BForm, BOverlay, BTable,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import ImportExcel from '@core/components/excel/ImportExcel.vue'
import JsonExcel from 'vue-json-excel'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import {
  tenant,
  bucketUrl,
  defaultExcelTemplate,
  defaultSecondPhoneNumberExcelTemplate,
} from '@/constants/config'

const ResourceRepository = RepositoryFactory.get('resource')
const MerchantRepository = RepositoryFactory.get('merchant')
const OrderRepository = RepositoryFactory.get('order')

export default {
  components: {
    BCard,
    ImportExcel,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BForm,
    BOverlay,
    vSelect,
    JsonExcel,
    BTable,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage, heightTransition],
  props: {
    orderSettings: {
      type: Object,
      default: null,
    },
    waybillPlaceholder: {
      type: Function,
      default: () => {},
    },
    waybillPattern: {
      type: Function,
      default: () => {},
    },
    patternTitle: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      tenant,
      bucketUrl,
      defaultExcelTemplate,
      defaultSecondPhoneNumberExcelTemplate,
      items: [],
      nextTodoId: 2,
      hidden: true,
      cityOptions: [],
      citinames: [],
      selectIndexRecods: '',
      tableData: [],
      header: [],
      sheetName: '',
      merchants: [],
      cities: [],
      businesses: [],
      is_waybill_auto: false,
      merchant: null,
      merchant_id: '',
      merchant_business_id: '',
      origin_city_id: '',
      fields: [
        {
          key: 'waybill_number', label: 'Waybill Number', editable: true, thClass: 'textSmall',
        },
        { key: 'order_no', label: 'Order Number', editable: true },
        { key: 'customer_name', label: 'Customer Name', editable: true },
        { key: 'customer_phone', label: 'Customer Phone', editable: true },
        { key: 'customer_address', label: 'Customer Address', editable: true },
        { key: 'customer_email', label: 'Customer Email', editable: true },
        { key: 'cod', label: 'COD', editable: true },
        { key: 'destination_city_id', label: 'Destination City', editable: true },
        { key: 'weight', label: 'Weight', editable: true },
        { key: 'description', label: 'Description', editable: true },
        { key: 'remark', label: 'Remark', editable: true },
      ],
      errors: [],
      errorFields: [
        {
          key: 'error',
          label: 'Error',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
      ],
      json_fields: {
        waybill_number: {
          field: 'waybill_number',
        },
        order_no: {
          field: 'order_no',
          callback: value => `"${value}"`,
        },
        customer_name: {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        customer_phone: {
          field: 'customer_phone',
          callback: value => `"${value.trim()}"`, // Trim spaces
        },
        customer_secondary_phone: {
          field: 'customer_secondary_phone',
          callback: value => `"${value.trim()}"`, // Trim spaces
        },
        customer_address: {
          field: 'customer_address',
          callback: value => `"${value}"`, // Wrap the address in double quotes
        },
        customer_email: {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        cod: {
          field: 'cod',
          callback: value => `"${value}"`,
        },
        destination_city_id: {
          field: 'destination_city_id',
          callback: value => {
            const cityName = this.destinationCityOptions[value] || ''
            return `"${cityName}"`
          },
        },
        weight: 'weight',
        description: {
          field: 'description',
          callback: value => `"${value}"`,
        },
        remark: {
          field: 'remark',
          callback: value => `"${value}"`,
        },
      },
      loading: false,
      excelTemplateExists: false,
      myLabels: {
        footerLeft: (top, bottom, total) => `${top} to ${bottom} of ${total}`,
        footerRight: 'none',
        processing: 'Processing',
        tableSetting: 'Table Setting',
        exportExcel: 'Export Excel',
        importExcel: 'Import Excel',
        back: 'Back',
        reset: 'Default',
        sortingAndFiltering: 'Sorting And Filtering',
        sortAscending: 'Sort Ascending',
        sortDescending: 'Sort Descending',
        near: '≒ Near',
        exactMatch: '= Exact Match',
        notMatch: '≠ Not Match',
        greaterThan: '&gt; Greater Than',
        greaterThanOrEqualTo: '≥ Greater Than or Equal To',
        lessThan: '&lt; Less Than',
        lessThanOrEqualTo: '≤ Less Than Or Equal To',
        regularExpression: '~ Regular Expression',
        customFilter: 'Custom Filter',
        listFirstNValuesOnly: n => `List first ${n} values only`,
        apply: 'Apply',
        noRecordIsRead: 'No record is read',
        readonlyColumnDetected: 'Readonly column detected',
        columnHasValidationError: (name, err) => `Column ${name} has validation error: ${err}`,
        noMatchedColumnName: 'No matched column name',
        invalidInputValue: 'Invalid input value',
        missingKeyColumn: 'Missing key column',
        noRecordIndicator: 'No record',
      },
    }
  },
  computed: {
    excelTemplateUrl() {
      return `${bucketUrl}excel-templates/${tenant}-template.xlsx`
    },
  },
  watch: {
    merchant(newMerchant, oldMerchant) {
      // Clear tableData when the selected merchant changes
      if (newMerchant !== oldMerchant) {
        this.fetchMerchantSettings()
      }
    },
  },

  async mounted() {
    this.initTrHeight()
    // await this.getExcelCityList()
    await this.getMerchantList()
    await this.fetchExcelTemplate()
    // await this.getCityList()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.getExcelCityList()
    this.destinationCityOptions = this.cityOptions.reduce((acc, item) => {
      acc[item.id] = item.name
      return acc
    }, {})
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    exportAsExcel() {
      const format = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'Orders'
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
    },
    newRecord() {
      const newRecord = {
        waybill_number: '',
        order_no: '',
        customer_name: '',
        customer_phone: '',
        customer_address: '',
        customer_email: '',
        cod: '',
        destination_city_id: '',
        weight: '',
        description: '',
        remark: '',
      }
      this.$refs.grid.newRecord(newRecord)
    },
    delRecord() {
      this.$refs.grid.deleteSelectedRecords() // delete the  record
    },
    validData(content) {
      if (content === null || content.trim() === '') return 'Mandatory field '
      return '' // return empty string if there is no error
    },
    validLengthDes(content) {
      if (content && content.length > 5) return 'Description should not exceed 190 characters'
      return '' // return empty string if there is no error
    },
    validLength(content) {
      if (content && content.length > 5) return 'Remark should not exceed 190 characters'
      return '' // return empty string if there is no error
    },
    validWeight(value) {
      if (value === null || value.trim() === '') {
        return 'Mandatory field' // or any other appropriate error message for empty input
      }
      const minWeight = this.orderSettings.weight_range_setting.min_weight
      const maxWeight = this.orderSettings.weight_range_setting.max_weight
      const numericValue = parseFloat(value)
      if (this.orderSettings.weight_range_setting.enabled === true && (Number.isNaN(numericValue) || numericValue < minWeight || numericValue > maxWeight)) {
        return `The order weight should be between ${minWeight} Kg to ${maxWeight} Kg`
      }
      return '' // return empty string if there is no error
    },
    validEmail(content) {
      if (content === null || content.trim() === '') return ''// Clear validation when content is empty
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(content)) {
        return 'Invalid Email Address'
      }
      return '' // return empty string if there is no error
    },
    formatPhoneNumber(phoneNumber) {
      return typeof phoneNumber === 'string' ? phoneNumber.trim() : phoneNumber
    },
    async loadDataInTable({ results, header, meta }) {
      this.loading = true
      this.header = header
      const loadedCities = []
      const lastEmptyRow = this.tableData.length // Track the last empty row index

      await Promise.all(
        results.map(async n => {
          if (n.destination_city) {
            if (!loadedCities.find(m => m === n.destination_city)) {
              loadedCities.push(n.destination_city)
              await this.getCityList(n.destination_city)
            }
          }
        }),
      )
      // Load data since the last empty row
      const newData = results.map(n => {
        const obj = n

        // Format phone numbers
        obj.customer_phone = this.formatPhoneNumber(obj.customer_phone)
        obj.customer_secondary_phone = this.formatPhoneNumber(obj.customer_secondary_phone)

        const destinationCityName = n.destination_city
          ? n.destination_city.toLowerCase()
          : ''
        obj.destination_city_id = destinationCityName
          ? (this.cities.find(
            m => m.name.toLowerCase() === destinationCityName,
          ) || {}).id
          : null
        return obj
      })
      // Update the tableData starting from the last empty row
      this.tableData.splice(lastEmptyRow, this.tableData.length - lastEmptyRow, ...newData)

      this.sheetName = meta.sheetName
      this.loading = false
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    async getMerchantList() {
      try {
        this.merchants = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await MerchantRepository.getMerchantListForDropdown()).data.data
        if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchants))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getBusinessList() {
      try {
        this.loading = true
        this.businesses = []
        this.merchant_business_id = null
        this.merchant_id = this.merchant.id
        const { data } = (await MerchantRepository.getMerchantBusinessListDropdown(this.merchant_id)).data
        this.businesses = data
        if (this.businesses.length === 1) {
          this.merchant_business_id = this.businesses[0].id
          await this.setDefaultOriginCity()
        }
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
      }
      this.fetchMerchantSettings()
      this.loading = false
    },
    async fetchMerchantSettings() {
      try {
        const { data } = (await MerchantRepository.getMerchantBusinessSettingsDetails(this.merchant_id)).data
        this.is_waybill_auto = data.is_waybill_auto
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getCityList(value) {
      try {
        const { data } = (await ResourceRepository.getCityDropdownList(value || '')).data
        data.forEach(n => {
          if (!this.cities.find(m => m.id === n.id)) {
            this.cities.push(n)
          }
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getExcelCityList() {
      try {
        this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityDropdownList()).data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async setDefaultOriginCity() {
      try {
        this.loading = true
        if (this.merchant_id && this.merchant_business_id) {
          const { data } = (await MerchantRepository.getMerchantBusinessDetails(this.merchant_id, this.merchant_business_id)).data
          await this.getCityList(data.business_default_address.city.name)
          this.origin_city_id = data.business_default_address.city_id
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchExcelTemplate() {
      try {
        await ResourceRepository.fetchExcelTemplate(this.excelTemplateUrl)
        this.excelTemplateExists = true
      // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
    async onClickSubmit() {
      this.loading = true
      const payload = {
        general_data: {
          sender_type: 'merchant',
          merchant_id: this.merchant_id,
          merchant_business_id: this.merchant_business_id,
          origin_city_id: this.origin_city_id,
        },
        order_data: [],
      }
      this.tableData.forEach(n => {
        const obj = {}
        Object.assign(obj, n)
        if (this.is_waybill_auto) {
          delete obj.waybill_number
        }
        payload.order_data.push(obj)
      })
      if (!payload.general_data.origin_city_id) delete payload.general_data.origin_city_id
      try {
        await OrderRepository.createBulkExcelOrder(payload)
        this.showSuccessMessage('Order created successfully')
        this.$router.push({ path: '/operations/all-orders' })
      } catch (e) {
        this.loadingResponse = false
        this.errors = []
        const { errors } = e.response.data
        if (typeof errors === 'string') {
          this.errors.push({ waybill_number: '---', error: errors })
        } else {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const error in errors) {
            const element = errors[error]
            this.errors.push({ error: element.toString() })
          }
          this.showErrorMessage('Some Validation errors persist, please check below grid!')
        }
      }
      this.loading = false
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async loadFromClipboard() {
      const dataset = this.parseClipboardData(this.clipboardData)
      this.tableData = dataset
      this.forceUpdate()
    },
    parseClipboardData(clipboardText) {
      const rows = clipboardText.split('\n')
      const headers = rows[0].split('\t')
      const newDataset = rows.slice(1).map(row => {
        const values = row.split('\t')
        const obj = Object.fromEntries(headers.map((header, index) => [header, values[index]]))
        obj.destination_city_id = this.cities.find(m => m.name.toLowerCase() === (obj.destination_city ? obj.destination_city.toLowerCase() : null)) ? this.cities.find(m => m.name.toLowerCase() === (obj.destination_city ? obj.destination_city.toLowerCase() : null)).id : null
        obj.waybill_number = this.is_waybill_auto ? 'AUTO GENERATE' : obj.waybill_number
        if (typeof obj.remark === 'undefined') {
          obj.remark = ''
        }
        return obj
      })
      // Append the new rows to the existing dataset
      const existingDataset = this.tableData // Assuming 'this.tableData' contains the existing dataset
      const updatedDataset = existingDataset.concat(newDataset)

      return updatedDataset
    },
  },
}
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.textSmall {
    max-width: 1000px;
}
.excelUploadtable{
  padding-bottom: 250px !important;
}
.centered-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50px;
}
.alert-heading {
  width: max;
}
</style>
